<template>
  <v-card>
    <v-card-title class="headline">Race Results</v-card-title>
    <v-card-subtitle>Your race results and earnings</v-card-subtitle>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.effectiveDate="{ item }">
          {{ item.effectiveDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </template>

        <template v-slot:item.payoutAmount="{ item }">
          {{ item.payoutAmount | toCurrency }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  name: "RacerResults",
  metaInfo: {
    title: "Racer Results"
  },
  data() {
    return {
      search: "",

      loading: false,
      items: [],
      headers: [
        {
          value: "effectiveDate",
          text: "Date",
          sortable: true
        },
        {
          value: "customFieldValue1",
          text: "Racer Type",
          sortable: true,
          align: "center"
        },
        {
          value: "customFieldValue2",
          text: "Series Name",
          sortable: true
        },
        {
          value: "customFieldValue3",
          text: "Promoter",
          sortable: true
        },
        {
          value: "customFieldValue4",
          text: "Class",
          sortable: true
        },
        {
          value: "customFieldValue5",
          text: "City",
          sortable: true
        },
        {
          value: "customFieldValue6",
          text: "Finishing Position",
          sortable: true,
          align: "right"
        },
        {
          value: "payoutAmount",
          text: "Award Amount",
          align: "right",
          sortable: true
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["effectiveDate"],
        sortDesc: [true]
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("MM/DD/YYYY");
    },
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 1;
      this.getData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let f = Object.assign({}, this.filters);

      f.payoutCategory = "RACE_AWARD";

      if (this.selectedParticipant && this.selectedParticipant.id) {
        f.participant = { id: this.selectedParticipant.id };
      }
      if (this.search && this.search.trim().length > 0) {
        f.keyword = this.search.trim();
      }

      this.$api
        .post(
          "/api/payouts/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          f
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>
